<template>
  <div class="home">
    <div class="login">
      <div class="login-wrap">
        <img src="../assets/img/logo.png" />
        <p>
          서로에게서 최고의 것을 발견하고, <br />
          최고의 것을 주는 사람들.<br />우리는 CARROT 입니다.
        </p>
        <div class="login-form">
          <input
            type="text"
            v-model="login.uid"
            placeholder="ID를 입력해주세요."
          />
          <input
            type="password"
            v-model="login.upw"
            @keyup.enter="login.doEnter"
            placeholder="비밀번호를 입력해주세요."
          />
        </div>
        <div class="mt-10 txt-left txt-white">
          <label>
            <input type="checkbox" v-model="login.check" /> ID 기억하기
          </label>
        </div>
        <button @click="login.doSubmit">LOGIN</button>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const cookies = useCookies();
    const login = reactive({
      uid: "",
      upw: "",
      check: "N",

      doSubmit: () => {
        login.rememberId();
        let params = {
          uid: login.uid,
          upw: login.upw,
        };

        if (!params.uid) {
          toast.error("이메일을 입력하세요.");
          return;
        }

        if (!params.upw) {
          toast.error("비밀번호를 입력하세요.");
          return;
        }

        axios.post("/rest/mypims/login", params).then((res) => {
          if (res.data.err == 0) {
            // 비밀번호 변경
            let userLoginInfo = {
              idx: res.data.idx,
              uid: login.uid,
            };
            store.commit("clearUserInfo");
            store.commit("setUserLoginInfo", userLoginInfo);

            if (res.data.pwd_change == "Y") {
              router.push({ name: "pwdChange" });
            } else {
              // 인가되지 않은 IP
              if (res.data.ip_check) {
                store.commit("setUserInfo", res.data);
                store.commit("setAuth");
                store.commit("setisToastVisible", false);
                router.push({
                  name: "main",
                });
              } else {
                router.push({
                  name: "authLogin",
                  params: {
                    idx: res.data.idx,
                    uid: login.uid,
                  },
                });
              }
            }
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      doEnter: () => {
        login.doSubmit();
      },
      checkId: () => {
        login.check = cookies.cookies.get("id_check");
        if (login.check == "true") login.uid = cookies.cookies.get("id");
      },
      rememberId: () => {
        cookies.cookies.set("id_check", login.check);
        cookies.cookies.set("id", login.uid);
        if (login.check == "true") {
          cookies.cookies.set("id", login.uid);
        }
        login.checkId();
      },
    });
    onMounted(() => {
      // Mounted
      login.checkId();
    });

    return { login };
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
</style>
